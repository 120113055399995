
import ReactDOM from 'react-dom';
import Home      from "./components/Home";

ReactDOM.render((
  <Home />
  ), document.getElementById('root'));

import * as THREE from 'three';

import { OBJLoader }      from 'three/examples/jsm/loaders/OBJLoader';

import model from './assets/3d/icon__osh.obj'

let stats;

let camera, scene, renderer;

let mouseX = 0, mouseY = 0;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;
let canvas = document.querySelector('canvas');

var isTouchDevice = 'ontouchstart' in document.documentElement;
var isActivelySelecting = false;
var currHoverTarget = null;

if (window.innerWidth < 1024) { // Mobile version
  window.addEventListener( 'touchmove', onDocumentTouchMove );
} else {
  document.querySelector('body').addEventListener( 'mousemove', onDocumentMouseMove );
}

init();
animate();

function init() {

  camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 10000 );
  camera.position.z = 3200;

  scene = new THREE.Scene();
  scene.background = new THREE.Color( 0x000000 );
  scene.matrixAutoUpdate = false;

  const material = new THREE.MeshNormalMaterial();

  const loader = new OBJLoader();
  loader.load( model, 	function ( object ) {

		object.traverse( function( child ) {
      if ( child instanceof THREE.Mesh ) {
          child.material = material;
      }
    } );

    scene.add( object );

    for ( let i = 0; i < 333; i ++ ) {

      const mesh = object.clone();

      mesh.position.x = Math.random() * 10000 - 5000;
      mesh.position.y = Math.random() * 10000 - 5000;
      mesh.position.z = Math.random() * 10000 - 5000;
      mesh.rotation.x = Math.random() * 2 * Math.PI;
      mesh.rotation.y = Math.random() * 2 * Math.PI;
      mesh.scale.x = mesh.scale.y = mesh.scale.z = Math.random() * 50 + 100;
      mesh.matrixAutoUpdate = false;
      mesh.updateMatrix();

      scene.add( mesh );

    }

  } );

  renderer = new THREE.WebGLRenderer( { canvas } );
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );

  window.addEventListener( 'resize', onWindowResize );

}

function onWindowResize() {

  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );

}

function onDocumentMouseMove( event ) {

  mouseX = ( event.clientX - windowHalfX ) * 10;
  mouseY = ( event.clientY - windowHalfY ) * 10;

}

function onDocumentTouchMove( event ) {
    // var event = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
    console.log('fdsafsd');
    var touch = event.touches[0] || event.changedTouches[0];
    console.log(touch);

    mouseX = ( touch.pageX - windowHalfX ) * 10;
    mouseY = ( touch.pageY - windowHalfY ) * 10;
}

//

function animate() {

  requestAnimationFrame( animate );

  render();

}

function render() {

  camera.position.x += ( mouseX - camera.position.x ) * .05;
  camera.position.y += ( - mouseY - camera.position.y ) * .05;

  camera.lookAt( scene.position );

  renderer.render( scene, camera );

}

document.addEventListener("DOMContentLoaded", function() {
    setTimeout(function(){ document.querySelector('body').classList.add('page-loaded') }, 1000);
    setTimeout(function(){ document.querySelector('body').classList.add('page-scrollable') }, 3000);
});
